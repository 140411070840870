import { connect } from 'react-redux';
import ReceiveAddressComponent from './ReceiveAddressComponent';
import { fetchProductDepositTemplate } from 'apex-web/lib/redux/actions/depositActions';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import config from 'apex-web/lib/config';

var mapStateToProps = function mapStateToProps(state, props) {
  return {
    userID: state.user.userInfo.UserId,
    deposit: state.deposit,
    account_provider_ID: props.product.account_provider_ID,
    WalletsDisabledMessage: config.global.WalletsDisabledMessage
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    selectDepositProduct: function selectDepositProduct(
      productId,
      account_Provider_ID
    ) {
      return dispatch(
        fetchProductDepositTemplate(productId, account_Provider_ID)
      );
    },
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiveAddressComponent);
