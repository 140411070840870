import apex from 'apex-web/lib/apex';

export let selectProduct = productID => dispatch =>
  dispatch({
    type: 'JOOMIO_SELECT_PRODUCT',
    payload: {
      productID
    }
  });
export let selectFiatProduct = productID => dispatch =>
  dispatch({
    type: 'JOOMIO_SELECT_FIAT_PRODUCT',
    payload: {
      productID
    }
  });
export let setCryptoBuyAmount = cryptoBuyAmount => dispatch =>
  dispatch({
    type: 'JOOMIO_SET_CRYPTO_BUY_AMOUNT',
    payload: {
      cryptoBuyAmount
    }
  });
export let setFiatPayAmount = fiatPayAmount => dispatch =>
  dispatch({
    type: 'JOOMIO_SET_FIAT_PAY_AMOUNT',
    payload: {
      fiatPayAmount
    }
  });
export let setCommissionAmount = commissionAmount => dispatch =>
  dispatch({
    type: 'JOOMIO_SET_COMMISSION_AMOUNT',
    payload: {
      commissionAmount
    }
  });
export let setCommissionRate = commissionRate => dispatch =>
  dispatch({
    type: 'JOOMIO_SET_COMMISSION_RATE',
    payload: {
      commissionRate
    }
  });
export let setCryptoBuyAmount0 = cryptoBuyAmount0 => dispatch =>
  dispatch({
    type: 'JOOMIO_SET_CRYPTO_BUY_AMOUNT_0',
    payload: {
      cryptoBuyAmount0
    }
  });
export let setFiatPayAmount0 = fiatPayAmount0 => dispatch =>
  dispatch({
    type: 'JOOMIO_SET_FIAT_PAY_AMOUNT_0',
    payload: {
      fiatPayAmount0
    }
  });

export let retrieveProductAttributes = async products => {
  if (products) {
    var _products = products;
    var product_data = await Promise.all(
      _products.map(async product => {
        product[
          'customAttributes'
        ] = await apex.connection.GetProductAttributes({
          ProductId: product.ProductId,
          OMSId: 1
        });
        return product;
      })
    );
    localStorage.setItem('product_data', JSON.stringify(product_data));
    return product_data;
  } else {
    return false;
  }
};
